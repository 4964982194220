.icn {
  @include stacking-context(default);
  font-size: 16px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: currentColor;
  width: 1em;
  height: 1em;
  transition: all 200ms;

  &:before, &:after {
    pointer-events: none;
  }
}

.icn.icn-strong {
  &:after, &:before {
    border-width: 2px;
  }
}

.icn.small {
  font-size: 12px;
}

.icn.large {
  font-size: 32px;
}

.icn-refresh {
  border-radius: 50%;
  margin: 4px;
  border: 2px solid currentColor;
  border-left-color: transparent;
  border-right-color: transparent;

  &:before, &:after {
    position: absolute;
    content: ' ';
    width: 0;
    height: 0;
    border: 0.25em solid currentColor;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
  }

  &:before {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    right: -5px;
    top: -1;
  }

  &:after {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    left: -5px;
    bottom: 0;
  }
}

.icn-filter {
  &:before {
    content: '';
    position: absolute;
    top: -2px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -8px;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: currentColor transparent transparent transparent;
  }

  &:after {
    content: '';
    z-index: -1;
    width: 4px;
    height: 9px;
    background-color: currentColor;
    transform: skew(0, 20deg);
    position: absolute;
    top: 3px;
    left: 50%;
    margin-left: -2px;
  }
}

.icn-sort {
  &:before {
    content: '';
    position: absolute;
    top: -2px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -7px;
    border-style: solid;
    border-width: 0 7px 7px 7px;
    border-color:  transparent transparent currentColor transparent ;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -7px;
    border-style: solid;
    border-width: 7px 7px 0 7px;
    border-color: currentColor  transparent transparent transparent ;
  }
}

.icn-close {
  &:before, &:after {
    content: ' ';
    background-color: currentColor;
    width: 0.75em;
    height: 2px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -50%;
    margin-top: -1px;
    transform-origin: center center;
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.icn-angle-up {
  &:before {
    content: '';
    display: inline-block;
    height: 0.6em;
    width: 0.6em;
    transform: translate(-50%, -50%) rotate(-45deg);
    transform-origin: center center;
    border-top: 1px solid currentColor;
    border-right: 1px solid currentColor;
    position: absolute;
    top: 55%;
    left: 50%;
  }
}

.icn-angle-down {
  &:before {
    content: '';
    display: inline-block;
    height: 0.6em;
    width: 0.6em;
    transform: translate(-50%, -50%) rotate(45deg);
    transform-origin: center center;
    border-bottom: 1px solid currentColor;
    border-right: 1px solid currentColor;
    position: absolute;
    top: 37%;
    left: 50%;
  }
}

.icn-angle-right {
  &:before {
    content: '';
    display: inline-block;
    height: 0.6em;
    width: 0.6em;
    //margin-left: -0.3em;
    //margin-right: -0.3em;
    transform: translate(-50%, -50%) rotate(-45deg);
    transform-origin: center center;
    border-bottom: 1px solid currentColor;
    border-right: 1px solid currentColor;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -0.15em;
  }
}

.icn-angle-left {
  &:before {
    content: '';
    display: inline-block;
    height: 0.6em;
    width: 0.6em;
    transform: translate(-50%, -50%) rotate(-45deg);
    transform-origin: center center;
    border-top: calc(1em / 16) solid currentColor;
    border-left: calc(1em / 16) solid currentColor;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -0.15em;
  }
}

.icn-tick {
  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
    content: '';
    height: 6px;
    width: 14px;
    position: relative;
    top: 50%;
    left: 50%;
    transform-origin: center center;
    display: block;
    border-bottom: 1px solid currentColor;
    border-left: 1px solid currentColor;
  }

  &.icn-tick-white {
    &:before {
      border-bottom: 1px solid #fff;
      border-left: 1px solid #fff;
    }
  }

  &.icn-tick-bold:before {
    border-bottom: 2px solid currentColor;
    border-left: 2px solid currentColor;
  }
}

.icn-envelope {
  height: 0.8em;
  width: 1em;
  border: 2px solid;
  border-color: inherit;
  overflow: hidden;
  position: relative;
  border-radius: 2px;

  &:before {
    content: "";
    height: 0.7em;
    width: 1em;
    border: 2px solid;
    border-color: inherit;
    position: absolute;
    border-radius: 3px;
    transform: rotate(-45deg);
    bottom: 5px;
    left: 0;
  }
}

.icn-info {
  position: relative;

  &:before {
    content: '\2139';
    position: absolute;
    top: 50%;
    left: 50%;
    content: '\2139';
    display: block;
    width: 16px;
    height: 16px;
    margin-left: -9px;
    margin-top: -10px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
  }

  &:after {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -0.6em;
    margin-left: -0.6em;
    border: 0.15em solid currentColor;
    border-radius: 50%;
    height: 1.2em;
    width: 1.2em;
  }
}

.icn-locate {
  $width: 1em;
  $height: 1em;
  $gap: 0.6;
  $size: 1.6em;
  $stroke: 0.08em;
  $line-length: 0.65em;

  $half-size: $size * 0.5;
  $stroke-center: $half-size - ($stroke * 0.5);

  box-sizing: border-box;
  z-index: 1;
  font-size: inherit;
  display: inline-block;
  width: 1.7rem;
  height: 1.7rem;
  position: relative;
  background-repeat: no-repeat;
  background-image:
          linear-gradient(currentColor, currentColor),
          linear-gradient(currentColor, currentColor),
          linear-gradient(currentColor, currentColor),
          linear-gradient(currentColor, currentColor);
  background-size:
          $stroke $line-length,
          $line-length $stroke,
          $stroke $line-length,
          $line-length $stroke;
  background-position:
          $stroke-center 0,
          ($size - $line-length) $stroke-center,
          $stroke-center ($size - $line-length),
          0 $stroke-center;

  &:before, &:after {
    content: "";
    position: absolute;
    top: calc(50% - #{$stroke});
    left: calc(50% - #{$stroke});
    background-color: transparent;
    border: $stroke solid currentColor;
  }

  &:before {
    border-radius: 50%;
    width: $width * $gap;
    height: $height * $gap;
    margin-left: ($width * ((1 * $gap) / 2)) * -1;
    margin-top: ($height * ((1 * $gap) / 2)) * -1;
  }

  &:after {
    border-radius: 50%;
    width: $width;
    height: $height;
    margin-left: ($width * -0.5);
    margin-top: ($height * -0.5);
  }
}


.icn-full-screen {
  vertical-align: middle;
  box-sizing: border-box;
  display: inline-block;
  border: .1em solid currentColor;
  width: 1em;
  height: 1em;
  position: relative;
  background-color: #fff;
}
.icn-full-screen:before, .icn-full-screen:after {
  content: '';
  position: absolute;
  background-color: #fff;
}
.icn-full-screen:before {
  width: .333em;
  height: 1.1em;
  left: .26em;
  top: -.1em;
  background-color: #fff;
}

.icn-full-screen:after {
  width: 1.1em;
  height: .333em;
  top: .26em;
  left: -.1em;
}