.image-driver {
  position: relative;
  background-color: $offer-red;
  margin-bottom: 2rem;

  img {
    height: 100%;
  }

  &.small {
    margin-bottom: 0;
    .image-driver--content {
      height: calc(215px - 1rem);
    }


    &.last {
      .image-driver--content {
        margin-top: 2rem;

        @include breakpoint(medium up) {
          margin-top: 0;
        }

        @include breakpoint(large up) {
          margin-top: 2rem;
        }
      }
    }
  }

  &.left {
    .image-driver--content {
      text-align: left;
    }

    .image-driver--hr {
      width: 60%;
      margin: 1rem 0;
    }

    .image-driver--button {
      margin: 0;
    }
  }

  &.no-button {
    .image-driver--button{
      display: none;
    }

    .image-driver--sub-heading {
      margin-bottom: 0;
    }

    .image-driver--hr {
      width: 60%;
      margin: 1rem 0;
    }

    .image-driver--content {
      text-align: left;
    }

  }
}

.image-driver--tag {
  position: absolute;
  top: 0;
  left: 0;
  max-width: calc(100% - 45px);
  min-height: 3rem;
  line-height: 1.4rem;
  z-index: 2;
  padding: 0.8rem 1rem;
  font-size: 1rem;
  background-color: currentColor;
  color: $rhino;
  text-align: left;
  text-transform: uppercase;

  span {
    color: white;
  }

  &:after {
    border-color: currentcolor transparent transparent currentcolor;
    border-style: solid;
    border-width: 1.6rem 1rem 1.4rem;
    content: " ";
    position: absolute;
    right: -2rem;
    top: 0;
  }

  &.cruise-deals {
    background-color: $gold;
    color: $white;

    &:after {
      border-color: $gold transparent transparent $gold;
    }
  }
}

.image-driver--logo {
  position: absolute;
  top: 1rem;
  left: 0;
  min-width: 50%;
  max-width: 90%;
  height: 3rem;
  z-index: 5;
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  line-height: 2rem;
  height: 3rem;

  //&.cruise-deals {
  //  top: 0;
  //  left: unset;
  //  right: 0;
  //  height: 5rem;
  //  width: 9rem;
  //  min-width: unset;
  //}

  img {
    display: inline-block;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
    position: relative;
    top: -2px;
  }

  &.logo {
    background-color: white;
    color: $dark-blue;


    &:after {
      border-bottom: 1.4rem solid transparent;
      border-left: 1rem solid white;
      border-top: 1.6rem solid white;
      border-right: 1rem solid transparent;
    }
  }

  &.tag {
    background-color: $dark-blue;
    color: white;
    text-align: left;
    text-transform: uppercase;

    &:after {
      border-bottom: 1.4rem solid transparent;
      border-left: 1rem solid $dark-blue;
      border-top: 1.6rem solid $dark-blue;
      border-right: 1rem solid transparent;
    }
  }

  &:after {
    content: ' ';
    position: absolute;
    right: -2rem;
    top: 0;

  }
}

.image-driver--image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background: linear-gradient(0deg,#39434f,#39434f 50%,transparent 75%,transparent);
  @include breakpoint (small only) {
    background-color: rgba(57, 67, 79, 0.5);
  }
}

.image-driver--content {
  display: block;
  position: relative;
  padding: 1.5rem;
  height: 430px;
  width: 100%;
  color: white;
  text-align: center;
  transition: background-color 500ms;

  &.no-padding {
    padding: 0;
  }

  p {
    transition: color 500ms;
  }

  .image-driver--button {
    transition: background-color 500ms;
    transition: color 500ms;
    transition: border-color 500ms;
  }

  &.cruise-deals {
    padding: 0;
    background-color: unset;

    &:hover {
      &:after {
        content: " ";
        background-color: rgba($hero-overlay, 0.7);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }
    }
  }

  &:hover {
    background-color: rgba(black, 0.9);
    .image-driver--button {
      background-color: $dark-blue;
      color: white;
      border-color: $dark-blue;
    }

    .image-driver--heading, .image-driver--sub-heading, p {
      color: $gold;
    }

    &.cruise-deals {
      background-color: rgba($hero-overlay, 0.9);

      &:hover {
        color: $gold;

        .promo-driver--button, .button {
          background-color: $white;
        }

        .image-driver--content-position-wrapper {
          background-color: unset;
          transition: 500ms;
        }

        .promo-driver--button {
          background-color: $gold;
          color: $white;
        }
      }
    }
  }

  &.image-driver--destinations {

    .image-driver--sub-heading {
      text-align: center;

      @include breakpoint(large up) {
        display: none;
      }

      &:hover {
        background: #39434f;
        opacity: 0.85;
      }
    }

    .image-driver--heading {
      text-align: center;
    }

    .location-driver--overlay {
      z-index: 0;
      @include breakpoint (small only) {
        background-color: rgba(57, 67, 79, 0.5);
      }
    }

    &:hover {
      background-color: #233654;
      opacity: 0.85;

      .image-driver--heading, .image-driver--sub-heading, p {
        color: $white;
      }

      .image-driver--sub-heading {
        display: block;
      }

      .image-driver--content-position-wrapper {
        bottom: 1rem;
      }
    }
  }

  hr {
    width: 20%;
  }
}

.image-driver--content-position-wrapper {
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  height: auto;
  width: calc(100% - 3rem);

  &.cruise-deals {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 45%;
    justify-content: space-between;
    background-color: $hero-overlay;
    bottom: 0;
    left: 0;
    opacity: 0.7;
    z-index: 2;
    padding: 0.8rem;

    p {
      margin-left: 0.2rem;
      margin-right: 0.2rem;
    }
  }
}

.image-driver--heading {
  font-size: 28px;
  color: white;
  transition: color 500ms;

  &.cruise-deals {
    font-size: 25px;
  }
}

.image-driver--content-position-wrapper .image-driver--sub-heading {
  transition: color 500ms;
  color: white;
  font-size: 1rem;
}

.image-driver--hr {
  display: block;
  width: 50px;
  height: 1px;
  margin: 1rem auto;
  background-color: white;

  &.cruise-deals {
    margin: 0.5rem auto;
  }
}

.image-driver--content-position-wrapper .image-driver--button {
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  line-height: 46px;
  height: 50px;
  cursor: pointer;
  -webkit-appearance: none;
  vertical-align: middle;
  border-radius: 0;
  padding: 0 1em;
  margin: 0 0 1rem 0;
  font-size: 0.8rem;
  border: 2px solid white;
  letter-spacing: 0.8px;
  color: white;
  transition: background-color 0.25s ease-out, border 0.25s ease-out, color 0.25s ease;
}

.image-driver--upper {
  display: flex;
  justify-content: space-between;
}

.promo-driver--button, .button {
  &.cruise-deals {
    width: 100%;

    &:hover {
      background-color: $hero-overlay;
      border-color: $gold;
    }
  }
}